import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconProfileCard.module.css';

const UPLOADIMAGE = "uploadimage";

const IconProfileCard = props => {
  const { className, rootClassName, brand } = props;
  const classes = classNames(rootClassName || css.root, className);

  switch (type) {
    case UPLOADIMAGE:
      return (
        <svg fill="#fff" width="15px" height="15px" viewBox="-0.056 0 0.356 0.356" xmlns="http://www.w3.org/2000/svg" class="cf-icon-svg"><path d="M0.238 0.172a0.019 0.019 0 0 1 -0.019 0.019H0.141v0.078a0.019 0.019 0 0 1 -0.039 0v-0.078H0.025a0.019 0.019 0 1 1 0 -0.039h0.078V0.075a0.019 0.019 0 0 1 0.039 0v0.078h0.078a0.019 0.019 0 0 1 0.019 0.019z" /></svg>
      );
      }
};

IconProfileCard.defaultProps = {
  className: null,
  rootClassName: null,
  brand: 'default',
};

IconProfileCard.propTypes = {
  className: string,
  rootClassName: string,
  brand: string,
};

export default IconProfileCard;
