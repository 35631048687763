/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';

import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../../components';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    listingLink,
    showCreateListingLink,
    isProvider
  } = props;

  const user = ensureCurrentUser(currentUser);
  const joinLink = process.env.REACT_APP_JOIN_LINK;
  if (!isAuthenticated) {
    // const signup = (
    //   <NamedLink name="SignupPage" className={css.signupLink}>
    //     <FormattedMessage id="TopbarMobileMenu.signupLink" />
    //   </NamedLink>
    // );

    // const login = (
    //   <NamedLink name="LoginPage" className={css.loginLink}>
    //     <FormattedMessage id="TopbarMobileMenu.loginLink" />
    //   </NamedLink>
    // );

    const signup = (
      <a href={joinLink} className={css.signupLink}><span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span></a>
    )

    const login = (
      <a href={joinLink} className={css.loginLink}> <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span></a>)

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );

    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const fullName = user.attributes.profile.publicData?.fullName;
  const authorDisplayName = !!fullName ? fullName : displayName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const circleCommunityLink = process.env.REACT_APP_CIRCLE_COMMUNITY_LINK;


  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName:authorDisplayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        {listingLink}
        {isProvider ? <NamedLink
          className={classNames(css.navigationLink, currentPageClass('DashboardPage'))}
          name="DashboardPage"
        >
          <span className={css.menuItemBorder} />
          <FormattedMessage id="TopbarMobileMenu.dashboardLink" />
        </NamedLink> : null}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        <a href={circleCommunityLink} className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}>
          <FormattedMessage id='TopbarMobileMenu.circleCommunity' />
        </a>
        <div className={css.spacer} />
      </div>
      {showCreateListingLink && <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div>}

    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
