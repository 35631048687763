import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
export const ASSET_NAME = 'landing-page';

const RESULT_PAGE_SIZE = 6;


// ================ Action types ================ //

export const LANDING_LISTINGS_REQUEST = 'app/LandingPage/LANDING_LISTINGS_REQUEST';
export const LANDING_LISTINGS_SUCCESS = 'app/LandingPage/LANDING_LISTINGS_SUCCESS';
export const LANDING_LISTINGS_ERROR = 'app/LandingPage/LANDING_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  landingListingsInProgress: false,
  landingListingsError: null,
  landingListingsResultIds: [],
};

const resultIds = data => data.data.map(l => l.id);

const landingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case LANDING_LISTINGS_REQUEST:
      return {
        landingListingsInProgress: true,
        landingListingsError: null,
        landingListingsResultIds: [],
      };
    case LANDING_LISTINGS_SUCCESS:
      return {
        landingListingsInProgress: false,
        landingListingsError: null,
        landingListingsResultIds: resultIds(payload.data),
      };
    case LANDING_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        landingListingsInProgress: false,
        landingListingsError: payload,
        landingListingsResultIds: [],
      };
    default:
      return state;
  }
};

export default landingPageReducer;

// ================ Action creators ================ //

export const landingListingsRequest = () => ({
  type: LANDING_LISTINGS_REQUEST
});

export const landingListingsSuccess = response => ({
  type: LANDING_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const landingListingsError = e => ({
  type: LANDING_LISTINGS_ERROR,
  error: true,
  payload: e,
});


export const landingFetchListings = (searchParams, config) => async (dispatch, getState, sdk) => {
  dispatch(landingListingsRequest());
  try {
    const response = await sdk.listings.query(searchParams);
    const listingFields = config?.listing?.customListingFields;
    const sanitizeConfig = { listingFields };
    dispatch(addMarketplaceEntities(response, sanitizeConfig));
    dispatch(landingListingsSuccess(response));
    return response;
  } catch (error) {
    dispatch(landingListingsError(storableError(error)));
    throw error;
  }
}

// export const loadData = (params, search) => dispatch => {
//   const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
//   return dispatch(fetchPageAssets(pageAsset, true));
// };

export const loadData = (params, search, config) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;
  
  return landingFetchListings({
    page:1,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images', 'author.profileImage'],
    'fields.listing': [
      'title',
      'geolocation',
      'publicData.listingType',
      'publicData.transactionProcessAlias',
      'publicData.rating',
      'publicData.reviews',
      // These help rendering of 'purchase' listings,
      // when transitioning from search page to listing page
      'publicData.pickupEnabled',
      'publicData.shippingEnabled',
      'publicData.isOnline'
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
    'fields.image': [
      'variants.scaled-small',
      'variants.scaled-medium',
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  },
  config);
}