import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { getAllTransitionsForEveryProcess } from '../../transactions/transaction';
import {
  bookingProcessAcceptedTransitions,
  bookingProcessActiveTransitions,
  bookingProcessCanceledTransitions,
  bookingProcessDeliveredTransitions,
  bookingProcessRequestedTransitions,
} from '../../util/bookingTransitions';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { onUploadFileToAWS } from '../../util/api';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';
export const UPLOAD_VIDEO_TO_AWS_REQUEST = 'app/InboxPage/UPLOAD_VIDEO_TO_AWS_REQUEST';
export const UPLOAD_VIDEO_TO_AWS_SUCCESS = 'app/InboxPage/UPLOAD_VIDEO_TO_AWS_SUCCESS';
export const UPLOAD_VIDEO_TO_AWS_ERROR = 'app/InboxPage/UPLOAD_VIDEO_TO_AWS_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  uploadVideoInProgress: false,
  uploadVideoError: null,
  uploadVideoSuccess: '',
};

export default function inboxPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case UPLOAD_VIDEO_TO_AWS_REQUEST:
      return {
        ...state,
        uploadVideoInProgress: true,
      };

    case UPLOAD_VIDEO_TO_AWS_SUCCESS:
      return {
        ...state,
        uploadVideoInProgress: false,
        uploadVideoSuccess: payload,
      };

    case UPLOAD_VIDEO_TO_AWS_ERROR:
      return {
        ...state,
        uploadVideoInProgress: false,
        uploadVideoSuccess: '',
        uploadVideoError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const uploadVideoRequest = () => ({
  type: UPLOAD_VIDEO_TO_AWS_REQUEST,
});

const uploadVideoSuccess = payload => ({
  type: UPLOAD_VIDEO_TO_AWS_SUCCESS,
  payload,
});

const uploadVideoError = payload => ({
  type: UPLOAD_VIDEO_TO_AWS_ERROR,
  payload,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

export const uploadVideoToAWS = body => dispatch => {
  dispatch(uploadVideoRequest());
  onUploadFileToAWS(body)
    .then(res => {
      dispatch(uploadVideoSuccess(res));
    })
    .catch(err => {
      dispatch(uploadVideoError(storableError(e)));
    });
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab } = params;

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1, nav = 'Requested' } = parse(search);

  let lasTransitions = [];
  if (nav === 'Requested') {
    lasTransitions = bookingProcessRequestedTransitions;
  } else if (nav === 'Active') {
    lasTransitions = bookingProcessAcceptedTransitions;
  } else if (nav === 'Delivered') {
    lasTransitions = bookingProcessDeliveredTransitions;
  } else if (nav === 'Cancelled') {
    lasTransitions = bookingProcessCanceledTransitions;
  }

  const apiQueryParams = {
    only: onlyFilter,
    lastTransitions: lasTransitions,
    include: [
      'listing',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
    ],
    'fields.transaction': [
      'processName',
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems',
      'protectedData',
    ],
    'fields.listing': ['title', 'availabilityPlan', 'publicData.listingType'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    perPage: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
