import { transitions as bookingProcessTransitions } from '../transactions/transactionProcessBooking'

export const bookingProcessAcceptedTransitions = [
    bookingProcessTransitions.ACCEPT,
    bookingProcessTransitions.OPERATOR_ACCEPT,
    bookingProcessTransitions.ACCEPT_24HOURS_DELIVERY,
    bookingProcessTransitions.OPERATOR_ACCEPT_24HOURS_DELIVERY,
    bookingProcessTransitions.MOVE_TO_YELLOW_ZONE,
    bookingProcessTransitions.MOVE_TO_RED_ZONE,
    bookingProcessTransitions.ACCEPT_RUSH_DELIVERY,
    bookingProcessTransitions.MOVE_TO_DELAY_RUSH_DELIVERY
];

export const bookingProcess24hrsDeliveryAcceptTransitions = [
    bookingProcessTransitions.ACCEPT_24HOURS_DELIVERY,
    bookingProcessTransitions.OPERATOR_ACCEPT_24HOURS_DELIVERY,
    bookingProcessTransitions.MOVE_TO_YELLOW_ZONE,
    bookingProcessTransitions.MOVE_TO_RED_ZONE
]

export const bookingProcessRequestedTransitions = [
    bookingProcessTransitions.REQUEST_PAYMENT,
    bookingProcessTransitions.INQUIRE,
    bookingProcessTransitions.REQUEST_PAYMENT_AFTER_INQUIRY,
    bookingProcessTransitions.CONFIRM_PAYMENT,
    bookingProcessTransitions.INQUIRE_RUSH_DELIVERY,
    bookingProcessTransitions.PROVIDER_CAME_BACK_ONLINE
];

export const bookingProcessDeliveredTransitions = [
    bookingProcessTransitions.COMPLETE,
    bookingProcessTransitions.OPERATOR_COMPLETE,
    bookingProcessTransitions.COMPLETE_24HOURS_DELIVERY,
    bookingProcessTransitions.OPERATOR_COMPLETE_24HOURS_DELIVERY,
    bookingProcessTransitions.COMPLETE_AFTER_YELLOW_ZONE,
    bookingProcessTransitions.OPERATOR_COMPLETE_AFTER_YELLOW_ZONE,
    bookingProcessTransitions.COMPLETE_AFTER_RED_ZONE,
    bookingProcessTransitions.OPERATOR_COMPLETE_AFTER_RED_ZONE,
    bookingProcessTransitions.REVIEW_1_BY_PROVIDER,
    bookingProcessTransitions.REVIEW_2_BY_PROVIDER,
    bookingProcessTransitions.REVIEW_1_BY_CUSTOMER,
    bookingProcessTransitions.REVIEW_2_BY_CUSTOMER,
    bookingProcessTransitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
    bookingProcessTransitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
    bookingProcessTransitions.EXPIRE_REVIEW_PERIOD,
    bookingProcessTransitions.COMPLETE_RUSH_DELIVERY,
    bookingProcessTransitions.COMPLETE_AFTER_DELAY    
];

export const bookingProcessCanceledTransitions = [
    bookingProcessTransitions.EXPIRE_PAYMENT,
    bookingProcessTransitions.OPERATOR_DECLINE,
    bookingProcessTransitions.EXPIRE,
    bookingProcessTransitions.CANCEL,
    bookingProcessTransitions.CANCEL_24HOURS_DELIVERY,
    bookingProcessTransitions.CANCEL_AFTER_YELLOW_ZONE,
    bookingProcessTransitions.CANCEL_AFTER_RED_ZONE
];
