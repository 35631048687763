import React from 'react'
import css from './OnlineDot.module.css'

const OnlineDot = () => {
    return <div className={css["ring-container"]}>
        <div className={css["ringring"]}></div>
        <div className={css["circle"]}></div>
    </div>
}

export default OnlineDot;