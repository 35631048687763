import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  H5,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    listingLink,
    showCreateListingLink,
    isProvider
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const userFirstName = currentUser?.attributes?.profile?.firstName;

  const joinLink = process.env.REACT_APP_JOIN_LINK;
  const circleCommunityLink = process.env.REACT_APP_CIRCLE_COMMUNITY_LINK;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };


  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        <H5 style={{ marginTop: '1rem' }}>{userFirstName}</H5>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          {listingLink ? listingLink : null}
        </MenuItem>
        {
          isProvider ?
            <MenuItem key="DashboardPage">
              <NamedLink
                className={classNames(css.profileSettingsLink, currentPageClass('DashboardPage'))}
                name="DashboardPage"
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.dashboardLink" />
              </NamedLink>
            </MenuItem>
            : <MenuItem key="empty"></MenuItem>
        }
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  // const signupLink = isAuthenticatedOrJustHydrated ? null : (
  //   <NamedLink name="SignupPage" className={css.signupLink}>
  //     <span className={css.signup}>
  //       <FormattedMessage id="TopbarDesktop.signup" />
  //     </span>
  //   </NamedLink>
  // );

  // const loginLink = isAuthenticatedOrJustHydrated ? null : (
  //   <NamedLink name="LoginPage" className={css.loginLink}>
  //     <span className={css.login}>
  //       <FormattedMessage id="TopbarDesktop.login" />
  //     </span>
  //   </NamedLink>
  // );

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <a href={joinLink} className={css.signupLink}><span className={css.signup}>
      <FormattedMessage id="TopbarDesktop.signup" />
    </span></a>
  )

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <a href={joinLink} className={css.loginLink}> <span className={css.login}>
      <FormattedMessage id="TopbarDesktop.login" />
    </span></a>
  )
      

  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        format="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      />
      {search}
      <div className={css.navs}>
        {showCreateListingLink && <NamedLink className={css.createListingLink} name="NewListingPage">
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.createListing" />
          </span>
        </NamedLink>}
        <a href={circleCommunityLink} className={css.inboxLink}>
          <span className={css.inbox}>
          <FormattedMessage id="TopbarDesktop.circleCommunity" />
          </span>
        </a>
        {inboxLink}
        {profileMenu}
        {signupLink}
        {loginLink}
      </div>

    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
