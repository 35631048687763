import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './AdvisorsCard.module.css';

const AdvisorsCard = props => {
    const {
        cardImage,
        advisorName,
        title,
        price
    } = props;
    return (
        <div className={css.listingCardBox}>
            <div className={css.cardImage}>
                <img src={cardImage} />
            </div>
            <div className={css.advisorBody}>
                <div className={css.advisorName}>{advisorName}</div>
                <div className={css.titleName}>{title}</div>
                <div className={css.startingPrice}>Starting at {price}</div>
            </div>
        </div>
    );
};

AdvisorsCard.defaultProps = {
    rootClassName: null,
    className: null,
};

const { string } = PropTypes;

AdvisorsCard.propTypes = {
    rootClassName: string,
    className: string,
};

export default AdvisorsCard;
