import React, { useEffect, useState } from 'react'
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import * as validators from '../../../util/validators'

import {
    Form,
    PrimaryButton,
    FieldTextInput,
    H4,
    H6,
    IconClose,
    FieldDateInput,
    FieldCheckbox
} from '../../../components';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { bool } from 'prop-types';

import css from './Listing.module.css';
import { FieldArray } from 'react-final-form-arrays';

import { getStartOf, initialVisibleMonth, isDateSameOrAfter, isDayMomentInsideRange, isInRange, monthIdString, timeOfDayFromLocalToTimeZone, timeOfDayFromTimeZoneToLocal } from '../../../util/dates';
import { deleteImageFromS3, uploadImageToS3 } from '../../../util/awsHelper';
import { propTypes } from '../../../util/types';
import { useConfiguration } from '../../../context/configurationContext';
import moment from 'moment';
import { rushDeliveryService } from '../../../config/configListing';

const MESSAGE_MAX_LENGTH = 1500;
const ACCEPT_IMAGES = 'image/*';
const nextTypeFunction = 'next';
const prevTypeFunction = 'prev';

//
const FieldAddImage = props => {
    const { formApi, onImageUpload, aspectWidth = 1, aspectHeight = 1, ...rest } = props;
    return (
        <Field form={null} {...rest}>
            {fieldprops => {
                const { accept, input, label, values, disabled: fieldDisabled } = fieldprops;
                const { name, type } = input;
                const images = values.images || [];
                const onChange = async e => {
                    const file = e.target.files[0];
                    const res = await onImageUpload(file);
                    const { Location, Key } = res || {};
                    if (Key && Location) {
                        formApi.change(`images`, [...images, { id: Key, url: Location }]);
                        formApi.blur(`images`);
                    }
                };
                const inputProps = { accept, id: name, name, onChange, type };
                return (
                    <div className={css.addImageWrapper}>
                        {fieldDisabled ? null : <input {...inputProps} className={css.addImageInput} />}
                        <label htmlFor={name} className={css.addImage}>
                            {label}
                        </label>
                    </div>
                );
            }}
        </Field>
    );
};

const FieldOrderImage = (props) => {
    const { imageUrl, alt, onRemoveImage } = props;

    return (
        <div className={css.orderAddedImage}>
            <button onClick={onRemoveImage} className={css.closeIcon}>
                <IconClose />
            </button>

            <img src={imageUrl} alt={alt} height={100} width={100} />
        </div>
    )
}

const TODAY = new Date();

const OrderFormComponent = (props) => {
    const [state, setState] = useState({ imageUploadRequested: false });
    const config = useConfiguration();
    const { listing: listingConfig } = config || {};
    const servicesField = listingConfig?.customListingFields?.filter(f => f.key === 'services')?.[0];
    const { enumOptions: serviceFieldOptions = [] } = servicesField || {};
    const initialSelectedService = props.initialValues?.service;
    const [showServices, setshowServices] = useState(false);
    const [selectedService, setSelectedService] = useState(initialSelectedService);
    const [mouseEntered, setMouseEntered] = useState(false);

    useEffect(() => setSelectedService(initialSelectedService), [initialSelectedService]);

    const toggleContent = () => {
        setshowServices(!showServices);
    };

    const handleSelectService = (formApi, service) => {
        setSelectedService(service);
        toggleContent();
        formApi.change('service', service);
    }


    const onImageUploadHandler = async (file) => {
        if (!file) return;

        setState({ imageUploadRequested: true });
        try {
            const response = await uploadImageToS3(file, file.name)
            setState({ imageUploadRequested: false });
            return response;
        } catch (error) {
            setState({ imageUploadRequested: false });
            console.log('Error!!:', error)
        }
    }


    const onImageRemoveHandler = async (fieldApi, imageUrl, imageIndex) => {

        fieldApi.remove(imageIndex); // Image deleted successfully

        try {
            await deleteImageFromS3(imageUrl)
        } catch (error) {
            //image deleting failed!
        }

    }

    const getMonthlyTimeSlots = (monthlyTimeSlots, date, timeZone) => {
        const monthId = monthIdString(date, timeZone);

        return !monthlyTimeSlots || Object.keys(monthlyTimeSlots).length === 0
            ? []
            : monthlyTimeSlots[monthId] && monthlyTimeSlots[monthId].timeSlots
                ? monthlyTimeSlots[monthId].timeSlots
                : [];
    };

    const nextMonthFn = (currentMoment, timeZone) =>
        getStartOf(currentMoment, 'month', timeZone, 1, 'months');
    const prevMonthFn = (currentMoment, timeZone) =>
        getStartOf(currentMoment, 'month', timeZone, -1, 'months');

    const endOfRange = (date, dayCountAvailableForBooking, timeZone) => {
        return getStartOf(date, 'day', timeZone, dayCountAvailableForBooking - 1, 'days');
    };


    const fetchMonthData = (date) => {
        const { listing, listingId, onFetchTimeSlots, dayCountAvailableForBooking } = props;
        const timeZone = listing?.attributes?.availabilityPlan?.timezone;
        const endOfRangeDate = endOfRange(TODAY, dayCountAvailableForBooking, timeZone);

        // Don't fetch timeSlots for past months or too far in the future
        if (isInRange(date, TODAY, endOfRangeDate)) {
            // Use "today", if the first day of given month is in the past
            const start = isDateSameOrAfter(TODAY, date) ? TODAY : date;

            // Use endOfRangeDate, if the first day of the next month is too far in the future
            const nextMonthDate = nextMonthFn(date, timeZone);
            const end = isDateSameOrAfter(nextMonthDate, endOfRangeDate)
                ? getStartOf(endOfRangeDate, 'day', timeZone)
                : nextMonthDate;

            // Fetch time slots for given time range
            onFetchTimeSlots(listingId, start, end, timeZone);
        }
    }


    return (
        <FinalForm
            {...props}
            mutators={{ ...arrayMutators }}
            render={fieldRenderProps => {
                const {
                    rootClassName,
                    className,
                    formId,
                    form,
                    handleSubmit,
                    inProgress,
                    invalid,
                    intl,
                    values,
                    authorDisplayName,
                    listing,
                    monthlyTimeSlots,
                    messageAlreadySaved,
                    updateInProgress,
                    updateProfileError,
                    isRushDelivery
                } = fieldRenderProps;

                //note: services which price is not set should not be included.
                const services = listing?.attributes?.publicData?.services || [];
                const prices = listing?.attributes?.publicData?.prices || [];
                const priceSetServices = prices.map(p => p.service);
                const filteredServices = services.filter(s => priceSetServices.includes(s.service));
                // console.log(values, 'values');
                // console.log(messageAlreadySaved, 'msg saved');
                const updatedServicesArr = filteredServices.map(s => {
                    const { service, id, mandatory, deliveryWithin24Hours } = s;
                    const priceObject = prices.find(price => price.service === service);
                    const foundServ = serviceFieldOptions.find(opt => opt.id === id);
                    let validity = foundServ?.validity ? foundServ.validity : 0;
                    return {
                        id,
                        title: service,
                        amount: priceObject?.amount,
                        mandatory,
                        deliveryWithin24Hours,
                        validity
                    };
                });

                // Adding Rush Delivery Service to choose your reading dropdown
                // (only if provider has turned on Rush Delivery)
                isRushDelivery && rushDeliveryService && updatedServicesArr.splice(1, 0, rushDeliveryService);

                const sortedServices = updatedServicesArr.sort((a, b) => a.validity - b.validity);

                const shouldRenderUpsellInput = (service) => {
                    const currentService = updatedServicesArr.filter(swa => swa.title === service)[0];
                    return currentService && !currentService.mandatory && !currentService.deliveryWithin24Hours;
                }

                const shouldRenderDateInput = (service) => {
                    const currentService = updatedServicesArr.filter(swa => swa.title === service)[0];
                    return currentService && !currentService.deliveryWithin24Hours && service !== rushDeliveryService?.title;
                }

                const timeZone = listing?.attributes?.availabilityPlan?.timezone;

                const bookingStartDate =
                    values.bookingStartDate && values.bookingStartDate.date ? values.bookingStartDate.date : null;

                const startOfToday = getStartOf(TODAY, 'day', timeZone);

                const [currentMonth, setCurrentMonth] = useState({ currentMonth: moment().tz(timeZone) })
                const [currentFunType, setCurrentFunType] = useState(null);

                useEffect(() => {
                    if (currentFunType === nextTypeFunction) {
                        fetchMonthData(nextMonthFn(currentMonth.currentMonth, timeZone))
                    } else if (currentFunType === prevTypeFunction) {
                        fetchMonthData(prevMonthFn(currentMonth.currentMonth, timeZone))
                    }
                }, [currentFunType, currentMonth])

                const onMonthClick = (monthFn, functionType) => {
                    setCurrentMonth(prevState => ({ currentMonth: monthFn(prevState.currentMonth, timeZone) }));
                    setCurrentFunType(functionType);
                }


                const timeSlotsOnSelectedMonth = getMonthlyTimeSlots(
                    monthlyTimeSlots,
                    currentMonth.currentMonth,
                    timeZone
                );


                const isDayBlocked = timeSlotsOnSelectedMonth
                    ? day =>
                        !timeSlotsOnSelectedMonth.find(timeSlot =>
                            isDayMomentInsideRange(
                                day,
                                timeSlot.attributes.start,
                                timeSlot.attributes.end,
                                timeZone
                            )
                        )
                    : () => false;

                //write validations here
                const serviceRequired = validators.required(intl.formatMessage({ id: 'CustomListingPage.Listing.serviceRequiredMessage' }));
                const messageequired = validators.required(intl.formatMessage({ id: 'CustomListingPage.Listing.messageRequiredMessage' }));

                const validateMessageLength = validators.maxLength(intl.formatMessage({ id: 'CustomListingPage.Listing.messageInvalidLengthMessage' }, { length: MESSAGE_MAX_LENGTH }), MESSAGE_MAX_LENGTH);

                //define class and conditions here
                const renderDateInput = shouldRenderDateInput(values.service);
                const renderUpsellInput = shouldRenderUpsellInput(values.service);

                const submitInProgress = inProgress || state.imageUploadRequested;
                const submitDisabled = invalid || submitInProgress || state.imageUploadRequested;
                const imageInputDisabled = state.imageUploadRequested || values?.images?.length >= 3;

                const formTitle = intl.formatMessage({ id: 'CustomListingPage.Lisitng.orderFormTitle' }, { advisor: authorDisplayName })
                const orderButtonText = intl.formatMessage({ id: 'CustomListingPage.Listing.orderFormButton' })
                const serviceFieldLabel = intl.formatMessage({ id: 'CustomListingPage.Listing.orderFormSelectServicesLabel' })
                const dateFieldLabel = intl.formatMessage({ id: 'CustomListingPage.Listing.orderFormDateFieldLabel' });
                const dateFieldPlaceholder = intl.formatMessage({ id: 'CustomListingPage.Listing.orderFormDateFieldPlaceholder' })
                const messageFieldLabelText = intl.formatMessage({ id: 'CustomListingPage.Listing.orderFormMessageFieldLabel' })
                const messageFieldHelperText = intl.formatMessage({ id: 'CustomListingPage.Listing.orderFormMessageFieldHelperText' })
                const imageFieldLabelText = intl.formatMessage({ id: 'CustomListingPage.Listing.orderFormImageInputFieldLabel' })
                const imageFieldHelperText = intl.formatMessage({ id: 'CustomListingPage.Listing.orderFormImageFieldHelperText' })
                const upsellCheckboxLabel = intl.formatMessage({ id: 'CustomListingPage.Listing.orderFormUpsellInputLabel' }, { price: '5.99' })
                const saveMsgLabel = intl.formatMessage({ id: 'CustomListingPage.Listing.orderFormSaveMessageLable' });
                const updateMsgLabel = intl.formatMessage({ id: 'CustomListingPage.Listing.orderFormUpdateMessageLabel' });;
                const saveOrUpdateMsgLabel = messageAlreadySaved ? updateMsgLabel : saveMsgLabel;


                return (
                    <Form onSubmit={handleSubmit}>
                        <H4>{formTitle}</H4>
                        <div className={css.orderFormBoxA}>
                            {/* <FieldSelect id="select-service"
                                className={css.inputBox}
                                name="service"
                                label={serviceFieldLabel} validate={serviceRequired}
                            >
                                <option value="">Choose a service...</option>
                                {sortedServices.map((s, i) => {
                                    const { amount, title, mandatory } = s;
                                    //since, price for mandatory services is already
                                    //aligned with them.
                                    const serviceFee = !mandatory ? `$${amount / 100}` : null;
                                    return <option key={i} value={title}>{title} {serviceFee}</option>
                                })}
                            </FieldSelect> */}
                            <div className={css.customDropdown}>
                                <div onClick={toggleContent} className={`${css.selectDropdown} ${selectedService ? css.default : null}`}>
                                    {selectedService ? selectedService : 'Choose a service...'}
                                </div>
                                {showServices &&
                                    <ul className={css.dropdownList} onMouseLeave={() => setMouseEntered(false)}>
                                        {sortedServices.map((s, i) => {
                                            const { amount, title, mandatory } = s;
                                            const serviceFee = !mandatory ? `$${amount / 100}` : null;
                                            return (<li className={(i === 0 && !mouseEntered && !selectedService) ? css.default : null} key={i} onClick={() => handleSelectService(form, title)} onMouseEnter={() => setMouseEntered(true)} >
                                                {title} {serviceFee}
                                            </li>)
                                        })}
                                    </ul>}
                            </div>

                            {renderDateInput ? <FieldDateInput
                                name="bookingStartDate"
                                id={formId ? `${formId}.bookingStartDate` : 'bookingStartDate'}
                                label={dateFieldLabel}
                                className={css.inputBox}
                                placeholderText={dateFieldPlaceholder}
                                format={v =>
                                    v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
                                }
                                parse={v =>
                                    v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
                                }
                                initialVisibleMonth={initialVisibleMonth(bookingStartDate || startOfToday, timeZone)}
                                isDayBlocked={isDayBlocked}
                                onPrevMonthClick={() => onMonthClick(prevMonthFn, prevTypeFunction)}
                                onNextMonthClick={() => onMonthClick(nextMonthFn, nextTypeFunction)}
                                useMobileMargins
                                validate={validators.bookingDateRequired(
                                    intl.formatMessage({ id: 'BookingTimeForm.requiredDate' })
                                )}
                                onClose={event =>
                                    setCurrentMonth({
                                        currentMonth: getStartOf(event?.date ?? TODAY, 'month', timeZone),
                                    })
                                }
                            /> : null}
                        </div>

                        <div className={css.checkboxServices}>
                            {renderUpsellInput && <FieldCheckbox id="upsell" name="upsell" label={upsellCheckboxLabel} value="true" />}
                        </div>
                        <FieldTextInput
                            type="textarea"
                            className={css.descriptionInput}
                            id="message"
                            name="message"
                            label={messageFieldLabelText}
                            placeholder={messageFieldHelperText}
                            validate={validators.composeValidators(messageequired, validateMessageLength)}
                        />

                        <div className={css.updateMessage}>
                            <FieldCheckbox className={css.saveMsg} id="saveMessage" name="saveMessage" label={saveOrUpdateMsgLabel} value="true" />
                            {updateProfileError && <span className={css.saveMsgError}>{saveOrUpdateMsgLabel} failed, please try again</span>}
                        </div>


                        {/* Image upload section */}

                        <div className={css.addImageSection}>
                            <div className={css.addImageField}>
                                <H6>{imageFieldLabelText}</H6>
                                <small>{imageFieldHelperText}</small>
                                <div className={css.modalImageSection}>
                                    <FieldAddImage
                                        formApi={form}
                                        onImageUpload={onImageUploadHandler}
                                        id="addImage"
                                        name="images"
                                        accept={ACCEPT_IMAGES}
                                        label={
                                            <span className={css.chooseImageText}>
                                                <span className={css.chooseImage}>
                                                    <FormattedMessage id="EditListingPhotosForm.chooseImage" />
                                                    {/* <IconProfileCard type="uploadimage" /> */}
                                                </span>
                                            </span>
                                        }
                                        type="file"
                                        disabled={imageInputDisabled}
                                        values={values}
                                    />
                                    <FieldArray name="images">
                                        {({ fields }) => {
                                            return (fields.map((name, index) => {
                                                const imageId = fields.value[index].id;
                                                const imageUrl = fields.value[index].url;

                                                return (
                                                    <FieldOrderImage
                                                        key={imageId}
                                                        imageUrl={imageUrl}
                                                        alt={name}
                                                        onRemoveImage={() => onImageRemoveHandler(fields, imageUrl, index)}
                                                        intl={intl}
                                                    />
                                                )
                                            }))
                                        }
                                        }
                                    </FieldArray>
                                </div>
                            </div>

                            {/* Field array here */}

                        </div>

                        <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                            {!updateInProgress ? orderButtonText : '...processing'}
                        </PrimaryButton>

                    </Form>
                )


            }
            }
        />
    )
};


OrderFormComponent.defaultProps = { inProgress: false };

OrderFormComponent.propTypes = {
    inProgress: bool,
    // from injectIntl
    intl: intlShape.isRequired,
    messageAlreadySaved: bool.isRequired,
    updateInProgress: bool.isRequired,
    updateProfileError: propTypes.error
};

const OrderForm = compose(injectIntl)(OrderFormComponent);

export default OrderForm;
