// ================ Types ================ //

import { addMarketplaceEntities } from "../../ducks/marketplaceData.duck";
import { fetchCompletedTransactions } from "../../util/api";
import { bookingProcess24hrsDeliveryAcceptTransitions } from "../../util/bookingTransitions";
import { storableError } from "../../util/errors";
import { fetchReviews } from "../ListingPage/ListingPage.duck";

export const FETCH_COMPLETED_TXS_REQUEST = 'app/DashboardPage/FETCH_COMPLETED_TXS_REQUEST';
export const FETCH_COMPLETED_TXS_SUCCESS = 'app/DashboardPage/FETCH_COMPLETED_TXS_SUCCESS';
export const FETCH_COMPLETED_TXS_ERROR = 'app/DashboardPage/FETCH_COMPLETED_TXS_ERROR';

export const FETCH_24HOURS_DELIVERABLE_TXS_REQUEST = 'app/DashboardPage/FETCH_24HOURS_DELIVERABLE_TXS_REQUEST';
export const FETCH_24HOURS_DELIVERABLE_TXS_SUCCESS = 'app/DashboardPage/FETCH_24HOURS_DELIVERABLE_TXS_SUCCESS';
export const FETCH_24HOURS_DELIVERABLE_TXS_ERROR = 'app/DashboardPage/FETCH_24HOURS_DELIVERABLE_TXS_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
    entities.map(entity => ({
        id: entity.id,
        type: entity.type,
    }));


const initialState = {
    queryCompletedTxsInProgress: false,
    queryCompletedTxsError: null,
    completedTxsRefs: [],
    query24hrsDeliverableTxsInProgress: false,
    query24hrsDeliverableTxsError: null,
    refs24hoursDeliverableTxs: []
};


const dashboardPageReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_COMPLETED_TXS_REQUEST:
            return { ...state, queryCompletedTxsInProgress: true, queryCompletedTxsError: null, completedTxsRefs: [] };
        case FETCH_COMPLETED_TXS_SUCCESS:
            return { ...state, queryCompletedTxsError: null, queryCompletedTxsInProgress: false, completedTxsRefs: entityRefs(payload.data.data) };
        case FETCH_COMPLETED_TXS_ERROR:
            return { ...state, queryCompletedTxsInProgress: false, completedTxsRefs: [], queryCompletedTxsError: payload };
        case FETCH_24HOURS_DELIVERABLE_TXS_REQUEST:
            return { ...state, query24hrsDeliverableTxsInProgress: true, query24hrsDeliverableTxsError: null, refs24hoursDeliverableTxs: [] };
        case FETCH_24HOURS_DELIVERABLE_TXS_SUCCESS:
            return { ...state, query24hrsDeliverableTxsError: null, query24hrsDeliverableTxsInProgress: false, refs24hoursDeliverableTxs: entityRefs(payload.data.data) }
        case FETCH_24HOURS_DELIVERABLE_TXS_ERROR:
            return { ...state, query24hrsDeliverableTxsInProgress: false, refs24hoursDeliverableTxs: [], query24hrsDeliverableTxsError: payload };
        default:
            return state;
    }
}

export default dashboardPageReducer;

// ================ Action Creators ================ //

export const completedTxsRequest = () => ({ type: FETCH_COMPLETED_TXS_REQUEST });
export const completedTxsSuccess = (response) => ({ type: FETCH_COMPLETED_TXS_SUCCESS, payload: response });
export const completedTxsError = (e) => ({ type: FETCH_COMPLETED_TXS_ERROR, payload: e })

export const query24hoursDeliverableTxsRequest = () => ({ type: FETCH_24HOURS_DELIVERABLE_TXS_REQUEST });
export const query24hoursDeliverableTxsSuccess = (response) => ({ type: FETCH_24HOURS_DELIVERABLE_TXS_SUCCESS, payload: response });
export const query24hoursDeliverableTxsError = (e) => ({ type: FETCH_24HOURS_DELIVERABLE_TXS_ERROR, payload: e })


// ================ Thunks ================ //
export const fetchCompletedTxs = () => async (dispatch, getState, sdk) => {
    dispatch(completedTxsRequest());
    try {
        //`fetchCompletedTransactions` will return all the completed transactions
        //of the current user, irrespective of the size.
        const response = await fetchCompletedTransactions();
        dispatch(addMarketplaceEntities(response));
        dispatch(completedTxsSuccess(response));
    } catch (error) {
        dispatch(completedTxsError(storableError(error)))
    }
}

export const fetch24hoursDeliveryTxs = () => async (dispatch, getState, sdk) => {
    dispatch(query24hoursDeliverableTxsRequest())
    try {
        const query = {
            only: 'sale',
            lastTransitions: bookingProcess24hrsDeliveryAcceptTransitions,
            include: ['customer', 'provider', 'listing', 'customer.profileImage', 'provider.profileImage'],
            'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
            'fields.image': ['variants.square-small', 'variants.square-small2x'],
            page: 1,
            perPage: 6
        }
        const response = await sdk.transactions.query(query);
        dispatch(addMarketplaceEntities(response));
        dispatch(query24hoursDeliverableTxsSuccess(response));
    } catch (error) {
        dispatch(completedTxsError(storableError(error)))
    }
}




export const loadData = (params, search, config) => (dispatch, getState) => {
    const reviewQuery = {
        state: 'public',
        include: ['author', 'author.profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
        perPage:6,
        page:1
    };

    return Promise.all([dispatch(fetchCompletedTxs()), dispatch(fetch24hoursDeliveryTxs()), dispatch(fetchReviews(null, reviewQuery))]);
}
