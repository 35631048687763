import React, { useEffect, useState } from 'react';
import css from './CountdownTimer.module.css';
import { states } from '../../transactions/transactionProcessBooking';



const calculateTimeLeft = (deliveryTime) => {
  const now = new Date().getTime();
  const difference = deliveryTime - now;

  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }
  return timeLeft;
};

const CountdownTimer = ({ deliveryTime, zone }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(deliveryTime));

  useEffect(() => {
    const timer = setInterval(() => {
      const timeLeft = calculateTimeLeft(deliveryTime);
      if (timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0) {
        clearInterval(timer);
      }
      setTimeLeft(timeLeft);
    }, 1000);

    return () => clearInterval(timer);
  }, [deliveryTime]);

  const timerClasses = zone === states.RED_ZONE ? css.red : zone === states.YELLOW_ZONE ? css.yellow : css.green;

  return (
    <div className={timerClasses}>
      {timeLeft.hours || timeLeft.minutes || timeLeft.seconds ? <div>
        {timeLeft.hours > 0 ? <span>{timeLeft.hours}hrs</span> : <span>00hrs</span>}
        {timeLeft.minutes > 0 ? <span>{timeLeft.minutes}mins</span> : <span>00mins</span>}
        {timeLeft.seconds > 0 ? <span>{timeLeft.seconds}sec</span> : <span>00sec</span>}
      </div> : <div>
        <h6>Service Delayed</h6>
      </div>}
    </div>

  );
};

export default CountdownTimer;
