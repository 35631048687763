import { storableError } from "../util/errors";

const structureListingFieldsData = (response) => {
    const { data } = response || {};
    if (!data || !data.length) {
      throw new Error('listing fields not found')
    };
  
    //helper to resolve enumOptions
    const resolveEnumOptions = (key, servicesOptions, categoriesOptions) => {
      if (key === 'services') {
        const enumOptions = servicesOptions.data.map(enumOpt => {
          const price = enumOpt.attributes.price ? Number(enumOpt.attributes.price) : null;
         
          return {
            id: `${enumOpt.id}`,
            ...enumOpt.attributes,
            price
          }
        })
        return enumOptions;
      } else if (key === 'categories') {
        const enumOptions = categoriesOptions.data.map(enumOpt => {
          return {
            id: `${enumOpt.id}`,
            ...enumOpt.attributes
          }
        })
        return enumOptions;
      }
      return [];
    }
  
    const updatedResponse = data.map(d => {
      const { attributes } = d;
      const { key, scope, schemaType, saveConfig, showConfig, filterConfig, includeForListingTypes, enumOptionsServices, enumOptionsCategories } = attributes;
      const enumOptions = resolveEnumOptions(key, enumOptionsServices, enumOptionsCategories);
      return {
        key,
        scope,
        schemaType,
        saveConfig,
        showConfig,
        filterConfig,
        includeForListingTypes,
        enumOptions
      }
    });
    return updatedResponse;
  }
  

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => payload => ({ type: actionType, payload, error: true });

export const FETCH_LISTING_FIELDS_REQUEST = 'app/listingFields/FETCH_LISTING_FIELDS_REQUEST';
export const FETCH_LISTING_FIELDS_SUCCESS = 'app/listingFields/FETCH_LISTING_FIELDS_SUCCESS';
export const FETCH_LISTING_FIELDS_ERROR = 'app/listingFields/FETCH_LISTING_FIELDS_ERROR';

export const fetchListingFieldsRequest = requestAction(FETCH_LISTING_FIELDS_REQUEST);
export const fetchListingFieldsSuccess = successAction(FETCH_LISTING_FIELDS_SUCCESS);
export const fetchListingFieldsError = errorAction(FETCH_LISTING_FIELDS_ERROR);


// =============== Reducer =============== //
const initialState = {
    listingFields: [],
    listingFieldsInProgress: false,
    listingFieldsError: null
}

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    if (type === FETCH_LISTING_FIELDS_REQUEST) {
        return { ...state, listingFieldsInProgress: true, listingFieldsError: null }
    } else if (type === FETCH_LISTING_FIELDS_SUCCESS) {
        return { ...state, listingFieldsInProgress: false, listingFields: payload }
    } else if (type === FETCH_LISTING_FIELDS_ERROR) {
        return { ...state, listingFieldsInProgress: false, listingFieldsError: payload }
    } else {
        return state;
    }
}


// ================ Thunk ================ //

export function requestListingFields() {
    return async (dispatch, getState, sdk) => {
        dispatch(fetchListingFieldsRequest());
        try {
            const listingFieldsApiUrl = process.env.REACT_APP_LISTING_FIELDS_API_URL;
            const response = await fetch(listingFieldsApiUrl);
            const responeData = await response.json()
            const structedData = structureListingFieldsData(responeData);
            dispatch(fetchListingFieldsSuccess({ data: structedData }));
            console.log('listing fields loaded.')
        } catch (error) {
            dispatch(fetchListingFieldsError(storableError(error)))
        }
    }
}
