import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_COUPON_DISCOUNT, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemDiscountMaybe = props => {
  const { lineItems, isCustomer, intl } = props;

  const discountLineItem = lineItems.find(
    item => item.code === LINE_ITEM_COUPON_DISCOUNT
  );

  return discountLineItem ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.couponDiscount" />
      </span>
      <span className={css.itemValue}>{formatMoney(intl, discountLineItem.lineTotal)}</span>
    </div>
  ) : null;
};

LineItemDiscountMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemDiscountMaybe;
