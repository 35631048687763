import { useState } from 'react';
import { func } from 'prop-types';
import css from './Coupon.module.css'

const CouponInput = ({ onAddCoupon, addCouponError }) => {
    const [code, setCode] = useState('');

    const couponInputChangeHandler = (e) => {
        setCode(e.target.value);
    }

    const onSubmitCoupanForm = (e) => {
        e.preventDefault();

        if (code && !!code) {
            onAddCoupon(code)
        }
    }


    return (
        <form onSubmit={onSubmitCoupanForm} className={css.couponForm}>
            <div className={css.couponFromLabel}>
                <label htmlFor='couponCode'><h4>Discount Code</h4></label>
                <small>Enter your discount code here</small>
            </div>
            <div className={css.couponInput}>
                <input
                    type="text"
                    id='couponCode'
                    onChange={couponInputChangeHandler}
                />

                <button type='submit' className={css.couponBtn}>Apply</button>
            </div>
            {addCouponError && <p className={css.addCouponError}>{addCouponError}</p>}
        </form>
    )
}

CouponInput.prototype = {
    onAddCoupon: func.isRequired
}

export default CouponInput;