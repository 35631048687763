// aws-config.js

const AWS = require('aws-sdk');

const region = process.env.REACT_APP_AWS_S3_REGION;
const accessKeyId = process.env.REACT_APP_AWS_S3_ACCESS_ID;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const basket = process.env.REACT_APP_AWS_S3_BUCKET_NAME;

AWS.config.update({
    region,
    accessKeyId,
    secretAccessKey
});

const s3 = new AWS.S3();

export const uploadImageToS3 = (file, fileName) => {
    const params = {
      Bucket: basket,
      Key: `${basket}-${new Date().getTime()}-${fileName}`,
      Body: file,
      ACL: 'public-read', // Makes the uploaded image public
    };
  
    return new Promise((resolve, reject) => {
      s3.upload(params, (err, data) => {
        if (err) {
          console.error('Error uploading image:', err);
          reject(err);
        } else {
          console.log('Image uploaded successfully:', data.Location);
          resolve(data);
        }
      });
    });
  };

export  const deleteImageFromS3 = (imageUrl) => {
    const params = {
      Bucket: basket,
      Key:imageUrl, // The path to your image in the bucket
    };
  
    return new Promise((resolve, reject) => {
      s3.deleteObject(params, (err, data) => {
        if (err) {
          console.error('Error deleting image:', err);
          reject(err);
        } else {
          console.log('Image deleted successfully');
          resolve();
        }
      });
    });
  };
  